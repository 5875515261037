.card-plan{
    border-radius: 1rem;
}

#company-plans .plan-header-speed{
    width: 52%;
    height: 130px;
    padding: 30px 15px;
    border-radius: 0 0 100px 0;
    -moz-border-radius: 1rem 0 100px 0;
    -webkit-border-radius: 1rem 0 100px 0;
}

.plan-header-value {
    width: 48%;
}

.red-plan{
    background-color: #ed1651;
}

.blue-plan{
    background-color: #5c2d91;
}

.gray-plan{
    background-color: #656565;
}

.green-plan{
    background-color: #28a745;
}

.plan-list{
    list-style: square !important;
}

.h-270px {
    height: 270px;
}

.plans-list::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}
   
.plans-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
}
   
.plans-list::-webkit-scrollbar-thumb {
    background-color: #ed164fbf;
    border-radius: 30px;
}