@import "./customCss/reset.css";
@import "./customCss/fonts.css";
@import "./customCss/animates.css";

[data-anime] {
  opacity: 0;
}
[data-anime].active {
  opacity: 1;
  animation: AnimateUp 0.5s forwards;
}

:root {
  --bodyColor: "#fff";
}

body {
  font-family: "Mulish", sans-serif !important;
  color: rgb(51, 51, 51);
  background-color: var(--bodyColor) !important;
}

body div#root > div:nth-child(2),
body div#root > section:nth-child(2) {
  margin-top: 67.7px;
}

@media (max-width: 767px) {
  body div#root > div:nth-child(2),
  body div#root > section:nth-child(2) {
    margin-top: 80px;
  }
}

* {
  transition: all 0.2s;
}

a {
  text-decoration: none !important;
}

a:link {
  text-decoration: none;
  color: #333;
}

a:visited {
  text-decoration: none;
  color: #333;
}

a:hover {
  text-decoration: none;
  color: #222;
}

a:active {
  text-decoration: none;
  color: #222;
}

.cookieButton a {
  color: #0f5132 !important;
}

.banner-list li {
  list-style-type: circle;
}

.grid-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.grid-icons > div {
  border: 1px solid gray;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.grid-icons img {
  width: 50px;
  height: 50px;
  margin: 1rem;
}

#carousel-video video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.h-900 {
  height: 900px !important;
}

ul {
  list-style: none;
}
ul .socialmedia-icons {
  display: flex;
  padding: 0;
}

section {
  padding: 50px 0;
}

section .section-title {
  font-weight: 400 !important;
  font-size: 35px;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
}

.overlay.mobile-overlay {
  z-index: 999;
}

.collapse.fade {
  transition: all 0.5s ease;
}

.btn-whatsapp {
  background-color: #25d366;
  color: #fff !important;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-whatsapp:hover {
  background-color: #25d366d9;
  color: black;
}

.lp-empresas {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lp-container {
  overflow: hidden;
}

.lp-button {
  left: 70px;
  position: relative;
}

.lp-footer {
  left: 150px;
  position: relative;
  margin-top: 1rem;
}

@media screen and (min-width: 1920px) {
  .lp-container {
    height: 900px;
  }

  /* .lp-button {
    top: 45rem;
    left: 30rem;
    position: absolute;
  } */
  /* styles for screens with a minimum width of 1920px go here */
}

@media screen and (max-width: 1280px) {
  .lp-container {
    height: 600px;
  }

  .lp-button {
    left: 100px;
    position: relative;
  }
  /* styles for screens with a minimum width of 1280px go here */
}

/*********************************
        UTILITARIES
*********************************/
.fs-sm {
  font-size: 0.8rem;
}

.cursor-pointer {
  cursor: pointer;
}

.ws-pre {
  white-space: pre;
}
.bg-gray {
  background-color: #f1f1f1ff;
}

.bg-light-gray {
  background-color: #f8f8f8ff;
}

.c-primary {
  color: #ed1651 !important;
}

.c-dark {
  color: #1f1f1f;
}

.c-white {
  color: #fff !important;
}

.fs-40 {
  font-size: 40px;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.c-gray {
  color: #858585ff;
}

.ln-12 {
  line-height: 1.2;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.w-30 {
  width: 30% !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-32 {
  padding-left: 32px;
}

.pl-50 {
  padding-left: 50px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-32 {
  margin-bottom: 32px;
}

.fs-18 {
  font-size: 18px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 14px;
}

.mt-5-r {
  margin-top: 5rem;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-50-m {
  padding: 50px;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-210 {
  margin-top: 210px !important;
}

.font-italic {
  font-style: italic;
}

.fs-20 {
  font-size: 20px;
}

.fs-28{
  font-size: 28px;
}

.m-l-5 {
  margin-left: 5rem;
}

.fs-25 {
  font-size: 25px;
}

.fs-45 {
  font-size: 45px;
}

.fs-60 {
  font-size: 60px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.wt-270 {
  max-width: 270px;
}

.wt-300 {
  max-width: 300px;
}

.wt-320 {
  max-width: 320px;
}

.wt-340 {
  max-width: 340px;
}

.wt-390 {
  max-width: 25%;
}

.wt-435 {
  max-width: 435px;
}

.wt-360 {
  max-width: 360px;
}

.wt-440 {
  max-width: 440px;
}

.wt-460 {
  max-width: 480px;
}

.border-radius-12 {
  border-radius: 12px;
}

.green-button{
  outline: 0;
  border: 0;
  background-color: #25d366;
  color: white !important;
  padding: 12px 17px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease-out;
  letter-spacing: 0.2px;
  max-width: 100%;
}

.blue-button {
  outline: 0;
  border: 0;
  background-color: #1a73e9;
  padding: 12px 17px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease-out;
  letter-spacing: 0.2px;
  max-width: 100%;
}

.outline-blue-button{
  outline: 0;
  background-color: transparent;
  color: #1a73e9;
  border: 1px solid #1a73e9;
  padding: 12px 17px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease-out;
  letter-spacing: 0.2px;
  max-width: 100%;
}

.blue-button:hover, .green-button:hover {
  opacity: 0.75;
}

.mobile-show {
  display: none;
}

.width-75 {
  width: 75%;
}

.width-50 {
  width: 50%;
}

#submitButton {
  margin-top: 20px !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

@-webkit-keyframes right-slide {
  100% {
    right: 0;
  }
}

@keyframes right-slide {
  100% {
    right: 0;
  }
}

@media (max-width: 767px) {
  .overflow-mobile {
    overflow: auto;
  }

  .grid-icons {
    grid-template-columns: repeat(2, 1fr);
  }

  .mobile-center {
    align-self: center;
  }
}

@media only screen and (max-width: 700px) {
  .m-w-100 {
    width: 100% !important;
  }
  .mobile-gap-2 {
    gap: 2rem;
  }

  #super-wifi #first-section {
    padding: 110px 0 !important;
    margin-top: 3rem;
  }

  .plan-top {
    height: 450px !important;
  }

  .mobile-hide-margin {
    /*padding: 0;*/
    margin-left: 0;
  }

  .menu-footer.conheca-menu-footer {
    margin-bottom: 2rem;
  }

  .mobile-show {
    display: flex;
  }

  .mobile-padding {
    padding: 30px;
  }

  #introduction-second {
    padding: 32px 0 0 0;
  }

  .mobile-font {
    font-size: 35px !important;
  }

  .mobile-title {
    padding: 12px 0;
    text-align: center;
    font-size: 20px !important;
  }

  .mobile-subtitle {
    text-align: center;
  }

  #announcement {
    padding: 0;
  }

  .mobile-img {
    padding: 10px 0;
  }

  /* Tv Section - Style */
  .mobile-mtb-50 {
    margin: 50px 0 !important;
  }

  .mobile-mt-20 {
    margin-top: 20px !important;
  }

  .mobile-mt-40 {
    margin-top: 40px !important;
  }

  .mobile-fs-20 {
    font-size: 20px !important;
    line-height: 30px;
  }

  .mobile-fs-14 {
    font-size: 14px !important;
    line-height: 30px;
  }

  .mobile-pb-16 {
    padding-bottom: 16px !important;
  }

  .mobile-fs-25 {
    font-size: 25px !important;
  }

  .mobile-fs-30 {
    font-size: 30px !important;
  }

  .mobile-fs-35 {
    font-size: 35px !important;
  }

  .mobile-mb-20 {
    margin-bottom: 20px !important;
  }

  /* Page Iot - Mobile */
  .mobile-padding-left {
    padding-left: 20px !important;
  }

  .mobile-text-center {
    text-align: center;
  }

  .mobile-text-left {
    text-align: start;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-position-image {
    position: relative;
    top: -20px;
    right: 0;
  }

  /* Page Trabalhe Conosco */

  .mobile-margin-5 {
    margin: 0 5px !important;
  }

  .mobile-ptb-10 {
    padding: 10px 0 !important;
  }

  /* SecondSection Component */
  .mobile-img-item {
    width: 80% !important;
    height: 310px !important;
  }

  .banner__offer {
    width: 100% !important;
    background: #ffffffa3 !important;
  }

  .banner__content {
    width: 100% !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 0.3rem;
  }

  .vertical-hr {
    display: none;
  }

  /* .offer-left{
    color: black !important;
  } */

  .por {
    font-size: 25px !important;
    /* color: black !important; */
    font-weight: 600 !important;
  }

  .price {
    font-size: 4rem !important;
    /* color: black !important; */
  }

  .megas {
    font-size: 1.5rem !important;
    /* color: black !important; */
  }

  .mes {
    line-height: 15px;
    font-weight: 600 !important;
    /* color: black !important; */
    font-size: 1.2rem !important;
  }

  .cents {
    font-size: 1.5rem !important;
    /* color: black !important; */
  }

  .third-col {
    justify-content: flex-end;
  }

  .mobile-h-150px {
    height: 150px !important;
  }
}
